import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import About from "./components/About";
import CompletedProjects from "./components/CompletedProjects";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/Main";
import RunningProjects from "./components/RunningProjects";
import LandingPage from "./components/LandingPage";
import ProjectDetailModal from "./components/ProjectDetailModal"; // Modal bileşenini ekleyin

function App() {
  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null); // Seçilen proje durumu

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000); // 3 saniye bekletme süresi
  }, []);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  return (
    <div className="App">
      <Toaster position="top-right" />
      {loading ? (
        <LandingPage />
      ) : (
        <div>
          <Header />
          <main className="mx-auto relative">
            <Main />
            <About />
            <RunningProjects />
            <CompletedProjects onProjectClick={handleProjectClick} />{" "}
            {/* Proje tıklama fonksiyonu ekleyin */}
            <Footer />
          </main>
          {selectedProject && (
            <ProjectDetailModal
              project={selectedProject}
              onClose={closeModal}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default App;
