import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import logo1 from "../assets/images/logo1.png";
import toast from "react-hot-toast";

const Footer = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      message: formData.message,
    };

    emailjs
      .send(
        "service_pre7664",
        "template_k2d9t0t",
        templateParams,
        "qg_cQK8yBkezvcctg"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          toast.success("Mesajınız gönderildi!");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          console.error("FAILED...", error);
          toast.error("Mesaj gönderilirken bir hata oluştu.");
        }
      );
  };
  return (
    <footer className="bg-[#E8E8E8] text-center py-6">
      <div className="text-2xl font-bold mb-6">Bizimle iletişime geçin</div>
      <div id="contact" className="items-center text-center py-10">
        <div className="flex justify-center">
          <form onSubmit={handleSubmit} className="w-full max-w-md">
            {/* Forma max-w-md sınıfı eklendi */}
            <div className="flex flex-col gap-2">
              <input
                type="text"
                placeholder="Adınız"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full py-4 px-2 text-xs rounded-md"
              />
              <input
                type="text"
                placeholder="Soyadınız"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full py-4 px-2 text-xs rounded-md"
              />
              <input
                type="email"
                placeholder="E-posta adresiniz"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full py-4 px-2 text-xs rounded-md"
              />
              <textarea
                placeholder="Mesajınız"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full py-4 px-2 text-xs rounded-md"
                rows={5}
              ></textarea>
              <button
                type="submit"
                className="bg-mainColor text-white rounded w-full py-3 px-2"
              >
                Gönder
              </button>
            </div>
          </form>
        </div>

        {/* İletişim Bilgileri */}
        <div className="mt-10">
          <div className="mb-2">
            Adres: Eski Büyükdere Caddesi No:61/2 Levent/İstanbul Türkiye
          </div>
          <div>
            E-Posta:{" "}
            <a
              href="mailto:info@borainsaat.com.tr"
              className="hover:text-mainColor"
            >
              info@borainsaat.com.tr
            </a>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
          {/* Logo ve Firma Adı */}
          <div className="flex items-center">
            <img src={logo1} alt="Logo" className="h-12 mr-2" />
          </div>
          {/* Menü */}
          <ul className="flex flex-wrap justify-center md:justify-start space-x-4 text-sm">
            <li>
              <a href="#main" className="hover:text-mainColor">
                Anasayfa
              </a>
            </li>
            <li>
              <a href="#about" className="hover:text-mainColor">
                Hakkımızda
              </a>
            </li>
            <li>
              <a href="#projects" className="hover:text-mainColor">
                Projelerimiz
              </a>
            </li>
            <li>
              <a href="#contact" className="hover:text-mainColor">
                İletişim
              </a>
            </li>
          </ul>
        </div>
        {/* Telif Hakkı */}
        <div className="text-center mt-8">
          <span className="text-xs">
            &copy; {new Date().getFullYear()} Bora İnşaat Limited Şirketi. Her
            hakkı saklıdır.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
