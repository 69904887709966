import React from "react";
import unimak1 from "../assets/images/unimak1.jpeg";
import unimak2 from "../assets/images/unimak2.jpg";

const RunningProjects = () => {
  return (
    <div id="projects" className="bg-gray-100 py-10 text-center">
      <div className="text-3xl font-bold mb-6 text-gray-800">
        Devam Eden Projelerimiz
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center">
        <div className="w-full md:w-1/3 text-left p-6 md:px-12 mb-6 md:mb-0">
          <p className="text-gray-700">
            <span className="font-semibold">Firma Adı:</span> Unimak Mühendislik
            Ltd Şti.{" "}
          </p>
          <p className="text-gray-700">
            <span className="font-semibold">Lokasyon:</span> Yalova Osb
            Tesisleri
          </p>
          <p className="text-gray-700">
            <span className="font-semibold">Açıklama:</span> 2 blok 39.000 m2
            betonarme çelik karma yapı inşaatı. Makine imalatı, boyahane montaj
            hatları ve depolar ile idari bina inşaatı.
          </p>
        </div>
        <div className="w-full md:w-2/3 overflow-x-auto">
          <div className="flex justify-center mt-6">
            <img
              src={unimak1}
              alt="Logo"
              className="w-auto h-60 rounded-lg mx-2"
            />
            <img
              src={unimak2}
              alt="Logo"
              className="w-auto h-60 rounded-lg mx-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RunningProjects;
