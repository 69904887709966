// LandingPage.jsx

import React from "react";
import logo1 from "../assets/images/logo1.png";

const LandingPage = () => {
  return (
    <div className="landing-container w-screen h-screen flex justify-center items-center overflow-hidden">
      <img src={logo1} alt="Company Logo" className="w-64 h-auto" />
    </div>
  );
};

export default LandingPage;
