import React, { useState } from 'react';
import { IoIosClose } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";

const ProjectDetailModal = ({ project, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (!project) return null;

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === project.photos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? project.photos.length - 1 : prevIndex - 1
    );
  };

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" 
      onClick={handleClickOutside}
    >
      <div 
        className="bg-white rounded-lg p-4 md:p-8 max-w-6xl w-full mx-2 md:mx-auto relative" 
        onClick={(e) => e.stopPropagation()}
      >
        <button onClick={onClose} className="absolute top-3 right-3">
          <IoIosClose size={28} className='text-mainColor' />
        </button>
        <h2 className="text-2xl font-bold mb-4">{project.isim}</h2>
        <div className="relative">
          <img
            src={project.photos[currentImageIndex]}
            alt={project.isim}
            className="mb-4 w-full md:h-[600px] object-cover rounded-md"
          />
          {project.photos.length > 1 && (
            <>
              <button
                onClick={handlePrevImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-mainColor text-white rounded-full p-2"
              >
                <FaArrowLeft />
              </button>
              <button
                onClick={handleNextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-mainColor text-white rounded-full p-2"
              >
                <FaArrowRight />
              </button>
            </>
          )}
        </div>
        <div className="text-center mb-4">
          {project.photos.length > 1 && (
            <span className='text-mainColor'>
              {currentImageIndex + 1} / {project.photos.length}
            </span>
          )}
        </div>
        <p>{project.description}</p>
      </div>
    </div>
  );
};

export default ProjectDetailModal;
