import React, { useEffect, useState } from "react";
import logo1 from "../assets/images/logo1.png";
import { FaArrowUp } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 80) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNavClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
    if (openMenu) {
      setOpenMenu(false);
    }
  };

  return (
    <header
      className={`transition-colors duration-300 absolute top-0 z-10 w-full ${
        openMenu ? "bg-white text-black" : "bg-transparent text-white"
      }`}
    >
      <div className="container mx-auto flex items-center justify-between font-bold p-4">
        <div className="flex items-center">
          <div className="mx-10">
            <img src={logo1} alt="Logo" className="h-14" />
          </div>
          <nav className="hidden md:flex">
            <div className="flex space-x-10">
              <a
                href="#main"
                className="hover:underline"
                onClick={(e) => handleNavClick(e, "main")}
              >
                Ana Sayfa
              </a>
              <a
                href="#about"
                className="hover:underline"
                onClick={(e) => handleNavClick(e, "about")}
              >
                Hakkımızda
              </a>
              <a
                href="#projects"
                className="hover:underline"
                onClick={(e) => handleNavClick(e, "projects")}
              >
                Projelerimiz
              </a>
              <a
                href="#contact"
                className="hover:underline"
                onClick={(e) => handleNavClick(e, "contact")}
              >
                İletişim
              </a>
            </div>
          </nav>
        </div>
        <button
          className="block md:hidden text-xl focus:outline-none"
          onClick={toggleMenu}
        >
          <GiHamburgerMenu />
        </button>
      </div>

      {openMenu && (
        <nav className="md:hidden bg-white text-black w-full">
          <div className="flex flex-col items-center space-y-4 py-4">
            <a
              href="#main"
              className="hover:underline"
              onClick={(e) => handleNavClick(e, "main")}
            >
              Ana Sayfa
            </a>
            <a
              href="#about"
              className="hover:underline"
              onClick={(e) => handleNavClick(e, "about")}
            >
              Hakkımızda
            </a>
            <a
              href="#projects"
              className="hover:underline"
              onClick={(e) => handleNavClick(e, "projects")}
            >
              Projelerimiz
            </a>
            <a
              href="#contact"
              className="hover:underline"
              onClick={(e) => handleNavClick(e, "contact")}
            >
              İletişim
            </a>
          </div>
        </nav>
      )}

      {showScroll && (
        <div className="fixed bottom-10 right-10">
          <button
            onClick={scrollToTop}
            className="text-white bg-[#403F3E] p-4 rounded-full focus:outline-none hover:bg-gray-800"
          >
            <FaArrowUp size={24} />
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
