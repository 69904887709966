import React from "react";
import aboutone from "../assets/images/about/bora2.jpeg";
import abouttwo from "../assets/images/about/about-2.png";
import { FaArrowRight } from "react-icons/fa";

export default function About() {
  return (
    <div id="about" className="text-center py-10">
      <div className="text-5xl font-bold">Hakkımızda</div>
      <div className="text-lg my-3 italic">Bizi yakından tanıyın...</div>
      <div className="italic mt-8">
        Farklı mimari çözümler geliştirerek ve en son teknolojiyi kullanarak,
        müşterilerimize ve yatırımcılarımıza modern projeler üretiyoruz.
      </div>
      <div className="italic mt-8 mx-auto container py-6">
        Bora İnşaat kurulduğu 1985 senesinden başlayarak hızlı bir şekilde
        yükselerek inşaat sektöründe öncü firmalar arasında kendine yer
        bulmuştur. Kalkınmanın yapı taşlarından biri olan inşaat sektörünün
        üstlendiği yaşamsal işlevler, en ince ayrıntılardan başlayarak daima en
        iyiyi hedeflemeyi gerektirir. Bu dev ve zorlu endüstride öncü bir rol
        üstlenen Bora İnşaat , gerçekleştirmiş olduğu ilk projeden bugüne , he
        zaman “zirve” yi hizmet anlayışının sınırlarını tanımlayan temel ölçüt
        olarak benimsemiştir.Çağdaş dünyanın hızla değişen beklentilerine en
        doğru çözümlerle yanıt verirken, gelişimde , başarıda , rekabette ve
        hizmette “zirve” ulaşılması hedeflenen değil , yola çıkılan noktayı
        oluşturmuştur….. Müteahhitlik, her şeyden önce, projelerin özündeki
        yaratıcılığa bağlılık duymayı gerektirir.bugune kadar dunyanin cesitli
        yerlerinde yapmis olduğu toplamda 668.000 m2 den fazla kapali alan insaa
        etmiş olmanin tecrübesinin yaninda bu süreçte hiçbir calsianinin kalici
        sakatlanmasina yol açan is kazasina sebebiyet vermemiştir.ve butun bu
        süreçlerde çevreye doğaya insana ve butun canlılara ayni hassasiyetle
        davranmis ve davranmaya devam etmektedir. Projenin kırılma noktası,onu
        bu hayalden gerçeğe taşırken sergilenecek söz konusu duyarlılıktır.Bora
        İnşaat müteahhitliğini gerçekleştirdiği her projeye bu çağdaş bakış
        açısıyla yaklaşır;tüm süreçlerde her emek verenin projenin özüyle
        bütünleştiği mükemmel bir işbirliği ve takım ruhu ile çalışır. İnşaat
        sektörünün farklı alanlarında aynı üstün uzmanlık ve köklü deneyimle
        hizmet verirken, tamamladığımız her yeni yaşama ve yaşamı hep daha iyiye
        götürmeyi hedefleyen kurumsal birikimimize yepyeni değerler kattığına
        inanıyoruz.En üst düzeyde müşteri memnuniyetini hedefleyerek hizmet
        ulaştırdığımız her yeni kurum ve her yeni birey de bu değerler
        zincirinin bir parçası olarak aramıza katılıyor.Geleceğin yeni
        hedeflerini birlikte güvenle karşılıyoruz. Bora İnşaatın, azerbaycandan
        gurcistana moritanyadan fildişi sahillerine kazakistandan montenegroya
        Bora İnşaat olmasında emeği geçen firma çalışanlarına, yerel ve yabancı
        partnerlerimize , alt yüklenicilerimize teşekkürlerimizi borç biliriz.
      </div>

      <div className="container mx-auto mt-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex justify-center items-center">
            <img src={aboutone} alt="About One" className="w-3/4 rounded-lg" />
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="text-lg font-bold">Vizyonumuz</div>
            <div className="text-sm mt-2">
              Yüklenicisi olduğu projelerin uzman kadromuz ve ekipman desteğimiz
              yardımıyla zamanında, şantiye ile merkezimiz arasındaki
              koordinasyon, önlem ve denetimler sayesinde kaliteli ve bütçesi
              dahilinde baştan sona “müşteri memnuniyeti” gözetilerek
              sonlandırılmasıdır.
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="text-lg font-bold">Misyonumuz</div>
            <div className="text-sm mt-2">
              Farklı mimari çözümler geliştirerek ve en son teknolojiyi
              kullanarak, müşterilerine ve yatırımcılarına modern projeler
              üretmek. Geliştirdiği projeler ile edindiği entelektüel sermaye
              birikimini, geleceğe taşıyarak, sürekli değişim ve gelişim
              içerisinde olmak. İnsanların yaşam kalitesini arttırarak, doğaya,
              çevreye saygılı yaşam alanları gerçekleştirmek ve sosyal
              sorumluluk bilincini daima ön planda tutmak.
            </div>
          </div>
          <div className="flex justify-center items-center">
            <img src={abouttwo} alt="About Two" className="w-3/4 rounded-lg" />
          </div>
        </div>
      </div>
      <div className="container mx-auto flex flex-col md:flex-row gap-10 justify-center mt-10">
        <div className="">
          <div className="font-bold text-mainColor text-xl">
            Neler Yapıyoruz?
          </div>
          <div className="italic text-lg mt-4">
            Geliştirdiğimiz projeler ile edindiğimiz entelektüel sermaye
            birikimini, geleceğe taşıyarak, sürekli değişim ve gelişim
            içerisindeyiz!
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-0">
          <ServiceItem
            title="Bina İnşaatı"
            description="İşyeri ve Plazalar, Alışveriş Merkezleri, Oteller ve Tatil Köyleri, Hastane Projeleri, Konut Projeleri, Kongre Merkezleri, Ticari Kompleksler."
          />
          <ServiceItem
            title="Mühendislik"
            description="Yollar, Otoyollar, Viyadükler, Köprüler, Enerji Tesisleri, Barajlar, Tesisat ve Montaj İşleri, Su Yapıları, alt yapı kazı ve iksa işleri."
          />
          <ServiceItem
            title="Endüstriyel"
            description="Ağır sanayi tesisleri, Betonlama ve Çelik Endüstri Tesisleri, LPG Tankları, Arıtma Tesisleri, HES Projeleri, Çelik Yapılar, Fabrika İnşaatları."
          />
          <ServiceItem
            title="Restorasyon"
            description="Tarihi eser onarım ve restorasyonu, Banka şubeleri dekorasyonu, Ofis binaları dekorasyonu, Rölöve, Restitüsyon ve Restorasyon Projeleri."
          />
        </div>
      </div>
    </div>
  );
}
const ServiceItem = ({ title, description }) => {
  return (
    <div className="flex flex-col items-center text-center mb-10">
      <div className="flex items-center mb-2">
        <FaArrowRight className="text-mainColor mr-2" size={22} />
        <div className="font-bold text-mainColor text-xl">{title}</div>
      </div>
      <div className="text-lg mt-4">{description}</div>
    </div>
  );
};
