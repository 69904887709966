import React from "react";
import ReactPlayer from "react-player";

export default function Main() {
  return (
    <div id="main" className="relative">
      <div className="player-wrapper relative">
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30"></div>
        <ReactPlayer
          className="react-player xs:w-full sm:w-full md:w-full lg:w-full xl:w-full"
          url="https://www.suffolk.com/wp-content/uploads/2021/02/ritz-small.mp4"
          width="100%"
          height="100%"
          autoPlay={true}
          controls={false}
          playing={true}
          loop={true}
        />
        <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white">
          <h1 className="md:text-2xl text-xl font-bold">Bizim felsefemiz</h1>
          <h1 className="md:text-6xl text-3xl font-bold">
            İnşa etmek. Yatırım. Yenilik.
          </h1>
        </div>
      </div>
    </div>
  );
}
