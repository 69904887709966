import React, { useState } from "react";
import projects from "../assets/data/projects.json";

const CompletedProjects = ({ onProjectClick }) => {
  const [selectedCategory, setSelectedCategory] = useState("Endustriyel Yapılar");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredProjects = projects[selectedCategory] || [];

  return (
    <div className="text-center py-10">
      <div className="text-3xl font-bold mb-4">Tamamlanan Projelerimiz</div>
      <span className="italic text-mainColor">
        Kataloğumuzu incelemek için{" "}
        <a href="/katalog.pdf" target="_blank" className="underline">
          tıklayınız.
        </a>
      </span>
      <div className="flex flex-wrap justify-center text-center gap-4 md:gap-10 mt-6">
        {Object.keys(projects).map((category) => (
          <div key={category}>
            <h2
              className={`text-lg font-bold cursor-pointer ${selectedCategory === category ? "text-mainColor" : ""}`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </h2>
          </div>
        ))}
      </div>
      <div className="flex flex-wrap justify-center container mx-auto mt-6">
        {filteredProjects.map((project) => (
          <div
            key={project.id}
            className="text-xs italic m-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex-none"
            onClick={() => onProjectClick(project)}
          >
            <div className="text-center cursor-pointer">
              <img
                src={project?.photos[0]}
                alt={project.isim}
                className="w-full h-60 md:h-72 lg:h-80 object-cover rounded-md mb-1"
              />
              <span className="font-bold text-lg">{project.isim}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompletedProjects;
